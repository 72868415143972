import styled from 'styled-components';
import {
	BellFill,
	BellSlashFill,
	Wifi,
	WifiOff,
	QuestionCircleFill,
	GearFill,
	CircleFill,
	PencilFill,
	EraserFill,
	PersonSquare,
	EyeFill,
	DoorClosedFill,
	DoorOpenFill,
	Chat,
	ChatFill,
	PinFill,
	PinAngleFill,
	PersonBadgeFill,
	PeopleFill,
	BroadcastPin,
	Eye,
	EyeSlash,
	PlugFill,
	CheckCircleFill,
} from '@styled-icons/bootstrap';

import ScreenShareIcon from '@/assets/icons/screenshare-solid.svg';
import Hommer from '@/assets/icons/Hommer.svg';
import Pinned from '@/assets/icons/Pinned.svg';
import Unpinned from '@/assets/icons/Unpinned.svg';
import BlockedCamera from '@/assets/icons/blocked-camera-icon.svg';
import Power from '@/assets/icons/power-off.svg';
import CaretDownLight from '@/assets/icons/chevron-down.svg';
import AlertTriangle from '@/assets/icons/alert-triangle-icon.svg';

import {
	BellConcierge,
	Ghost,
	Hand,
	Toolbox,
	Video,
	VideoSlash,
	Microphone,
	MicrophoneSlash,
	User,
	Headphones,
	Globe,
	Message,
	Headset,
	RightFromBracket,
	X,
	VolumeHigh,
	VolumeXmark,
	CaretDown,
	TriangleExclamation,
	UserGroup,
	UserPlus,
	MagnifyingGlass,
	MobileScreenButton,
	Desktop,
	ArrowRotateRight,
	Shuffle,
	DownLeftAndUpRightToCenter,
	Bars,
} from '@styled-icons/fa-solid';

export const Concierge = styled(BellConcierge)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const Bell = styled(BellFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const BellOff = styled(BellSlashFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const WifiIcon = styled(Wifi)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const WifiOffIcon = styled(WifiOff)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const QuestionIcon = styled(QuestionCircleFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const SettingsIcon = styled(GearFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const PowerIcon = styled(Power)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const CircleIcon = styled(CircleFill)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;

export const PencilIcon = styled(PencilFill)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;
export const EraserIcon = styled(EraserFill)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;
export const HandIcon = styled(Hand)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const PersonIcon = styled(PersonSquare)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const ViewIcon = styled(EyeFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const DoorIcon = styled(DoorOpenFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const DoorClosedIcon = styled(DoorClosedFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const ChatIcon = styled(Chat)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const ChatFillIcon = styled(ChatFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const PinOn = styled(PinFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const PinOff = styled(PinAngleFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const UserIcon = styled(User)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const Instructor = styled(PersonBadgeFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const Students = styled(PeopleFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const BroadcastPinIcon = styled(BroadcastPin)`
	color: ${(props) => props.color || 'red'};
	width: ${(props) => props.width || '26px'};
	height: ${(props) => props.height || '26px'};
`;

export const ToolboxIcon = styled(Toolbox)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const GhostIcon = styled(Ghost)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const CameraIcon = styled(Video)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const CameraIconOff = styled(VideoSlash)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const MicIcon = styled(Microphone)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const MicIconOff = styled(MicrophoneSlash)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const HeadphonesIcon = styled(Headphones)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const GlobeIcon = styled(Globe)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const MessageIcon = styled(Message)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const HeadsetIcon = styled(Headset)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const MutedIcon = styled(VolumeXmark)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const UnmutedIcon = styled(VolumeHigh)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;
export const CaretDownIcon = styled(CaretDown)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '16px'};
	height: ${(props) => props.height || '16px'};
`;
export const CaretDownLightIcon = styled(CaretDownLight)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '16px'};
	height: ${(props) => props.height || '16px'};
`;

export const RightFromBracketIcon = styled(RightFromBracket)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '30px'};
	height: ${(props) => props.height || '30px'};
`;

export const CloseIcon = styled(X)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;
export const WarningIcon = styled(TriangleExclamation)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;
export const UserGroupIcon = styled(UserGroup)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;
export const UserPlusIcon = styled(UserPlus)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;
export const PhoneIcon = styled(MobileScreenButton)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;
export const DesktopIcon = styled(Desktop)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;

export const SearchIcon = styled(MagnifyingGlass)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;

export const RefreshIcon = styled(ArrowRotateRight)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;
export const ShuffleIcon = styled(Shuffle)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;

export const OpenMenuIcon = styled(Bars)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;

export const CollapseMenuIcon = styled(DownLeftAndUpRightToCenter)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;

export const PlugIcon = styled(PlugFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '22px'};
	height: ${(props) => props.height || '22px'};
`;

export const CheckCircleFillIcon = styled(CheckCircleFill)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '22px'};
	height: ${(props) => props.height || '22px'};
`;

export const AlertIcon = styled(AlertTriangle)`
	color: ${(props) => props.color || 'white'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;

export const ShowPassIcon = styled(Eye)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;

export const HidePassIcon = styled(EyeSlash)`
	color: ${(props) => props.color || 'black'};
	width: ${(props) => props.width || '20px'};
	height: ${(props) => props.height || '20px'};
`;

export const ScreenShare = (props: {
	width?: string;
	height?: string;
	color?: string;
}) => <ScreenShareIcon {...props} alt="icon" />;

export const BlockedCameraIcon = (props: {
	width?: string;
	height?: string;
	color?: string;
}) => <BlockedCamera {...props} alt="icon" />;

export const PinnedIcon = (props: {
	width?: string;
	height?: string;
	color?: string;
}) => <Pinned {...props} alt="icon" />;

export const UnpinnedIcon = (props: {
	width?: string;
	height?: string;
	color?: string;
}) => <Unpinned {...props} alt="icon" />;

export const HommerIcon = (props: {
	width?: string;
	height?: string;
	color?: string;
}) => <Hommer {...props} alt="icon" />;
