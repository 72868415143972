import { atom } from 'recoil';

export const viewerIdAtom = atom({
	key: 'userIdAtomKey',
	default: '' as string,
});

export const classSlugAtom = atom({
	key: 'classSlugAtomKey',
	default: '' as string,
});

export const isAuthenticatedAtom = atom({
	key: 'isAuthenticatedAtomKey',
	default: false as boolean,
});

export const isVerifiedAtom = atom({
	key: 'isVerifiedAtomAtomKey',
	default: false as boolean,
});

export const isCollapsedSidebarAtom = atom({
	key: 'isCollapsedSidebarKey',
	default: false,
});

export const isSettingsOpen = atom({
	key: 'isSettingsOpenKey',
	default: false,
});

export const isConnectionTestOpen = atom({
	key: 'isConnectionTestOpenKey',
	default: false,
});

export const isSessionConnectedAtom = atom({
	key: 'isSessionConnectedAtomKey',
	default: false,
});
export const connectionIdAtom = atom({
	key: 'connectionIdAtomKey',
	default: false,
});
export const isScreenSharingAtom = atom({
	key: 'isScreenSharingAtomKey',
	default: false,
});

export const isUserLoggingOutAtom = atom({
	key: 'isUserLoggingOutAtomKey',
	default: false,
});
export const appSettingsAtom = atom({
	key: 'appSettingsAtomKey',
	default: {
		defaultRemoteAccess: false,
		studentLogoutTimeout: 9,
		instructorLogoutTimeout: 30,
		defaultLogoutTimeout: 240,
	},
});

export const appStatusAtom = atom({
	key: 'appStatusAtomKey',
	default: {
		sessionStatus: 0,
		connectionStatus: 0,
		publisherStatus: 0,
		networkStatus: 1,
	},
});

export const graphQLsubscriptionStatus = atom({
	key: 'graphQLsubscriptionStatusKey',
	default: {
		MY_CLASS_USER_STATUS: true,
		INSTRUCTORS_IN_CLASS_SUBSCRIPTION: true,
		LOBBY_USER_SUBSCRIPTION: true,
		STUDENT_IN_CLASS_SUBSCRIPTION: true,
		GROUPS_SUBSCRIPTION: true,
	},
});